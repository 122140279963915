<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 flex flex-col justify-center items-center"
        >
          <div
            class="p-2 modal-header flex justify-center items-center rounded-full"
          >
            <img
              rel="prefetch"
              src="@/assets/images/user-contact.png"
              alt="user-plus"
              :style="{ width: '80%' }"
            />
          </div>

          <div
            class="w-8/12 modal-body mt-5 mb-0 font-proximaNormal flex flex-col"
          >
            <div v-if="result.invitation > 0" class="mt-8 flex">
              <div class="ml-1 mr-4 flex flex-col justify-start items-start">
                <UserCheckIcon />
              </div>
              <div class="ml-1 flex flex-col justify-start items-start">
                <h3>
                  You are inviting {{ result.invitation }} friend(s) to connect!
                </h3>
              </div>
            </div>
            <div v-if="result.referral > 0" class="mt-4 flex">
              <div class="ml-1 mr-4 flex flex-col justify-start items-start">
                <GiftIcon />
              </div>
              <div class="ml-1 flex flex-col justify-start items-start">
                <h3>
                  Successfully added {{ result.referral }} new referral(s)!
                </h3>
              </div>
            </div>
            <div v-if="result.friend > 0" class="mt-4 flex">
              <div class="ml-1 mr-4 flex flex-col justify-start items-start">
                <UserIcon />
              </div>
              <div class="ml-1 flex flex-col justify-start items-start">
                <h3>
                  {{ result.friend }} friend(s) will be added to your friend
                  list.
                </h3>
              </div>
            </div>
          </div>

          <div
            class="modal-footer w-full flex flex-col md:flex-row justify-center items-center"
          >
            <BorderedButton
              class="md:mr-1"
              :title="$t('global_cancel')"
              borderColor="border-mediumGrey"
              textCl="text-darkGrey"
              paddingX="px-5"
              width="w-11/12 md:w-4/12"
              @click.native="$emit('close')"
            />
            <SubmitButton
              class="md:ml-1"
              :title="$t('global_confirm')"
              background="bg-success"
              paddingX="px-5"
              width="w-11/12 md:w-4/12"
              @click.native="$emit('close')"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import TrashIcon from "../icons/TrashIcon.vue";
import CheckIcon from "../icons/CheckIcon.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import UserIcon from "../icons/UserIcon.vue";
import UserCheckIcon from "../icons/UserCheckIcon.vue";
import GiftIcon from "../icons/GiftIcon.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    show: Boolean,
    contactEmailAddress: String,
    contactphoneNo: String,
    contactName: String,
    emergencyContact: Boolean,
    joinPerpetual: Boolean,
  },
  components: {
    TrashIcon,
    CheckIcon,
    SubmitButton,
    BorderedButton,
    UserIcon,
    UserCheckIcon,
    GiftIcon,
  },
  computed: {
    result() {
      console.log("8888888", this.$store.state.result);
      return this.$store.state.result;
    },
  },
  methods: {
    openConnectRequestModals() {
      this.$store.commit("addNonContactRequestModals", true);
    },
    closeContactRequest() {
      this.note = "";
      this.$store.commit("error", "");
      this.$store.commit("addNonContactRequestModals", false);
    },
  },
};
</script>

<style scoped lang="scss">
ul {
  list-style: none; /* Remove default bullets */
}

ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #0ac0cb; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
