<template>
  <div class="relative">
    <div
      @click="dropdown = !dropdown"
      v-click-outside="ddfalse"
      class="
        bg-primary
        text-white
        py-3
        px-4
        flex
        justify-between
        items-center
        border-r-4 border-orange
      "
    >
      <div class="flex items-center">
        <span class="ml-0 text-base">{{ status }}</span>
      </div>
      <ArrowNext
        style="transform: rotate(90deg)"
        bgColor="transparent"
        iconColor="#fff"
      />
    </div>
    <ul
      v-if="dropdown"
      class="w-full mt-12 bg-greyishWhite outline border absolute -top-14"
    >
      <SelectEach
        v-for="(list, i) in dataList"
        :key="i"
        @click.native="selectStatus(list.name)"
        :title="list.name"
      />
    </ul>
  </div>
</template>

<script>
import SelectEach from "./SelectEach.vue";
import ArrowNext from "../../icons/ArrowNext.vue";

export default {
  props: ["dataList"],
  components: {
    SelectEach,
    ArrowNext,
  },
  data() {
    return {
      dropdown: false,
      status: "Select Pet",
    };
  },
  methods: {
    ddfalse() {
      this.dropdown = false;
    },
    selectStatus(i) {
      this.status = i;
      this.$emit("selected", i);
    },
  },
};
</script>

<style>
</style>