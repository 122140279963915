<template>
  <div>
    <span v-if="!textImage">{{ pet.callName }}</span>
    <img
      v-else
      class="rounded-full m-1 bg-cover h-8 w-8 cursor-pointer"
      :src="img"
      :alt="pet"
      @click="goTo('PublicProfile', pet)"
    />
  </div>
</template>
<!-- species -->

<script>
import { pictureBySpecies } from "../../methods/petMethods";
export default {
  props: ["pet"],
  data() {
    return {
      img: null,
    };
  },
  async created() {
    this.img = this.pet.profile_picture
      ? this.pet.profile_picture
      : pictureBySpecies(this.pet);
  },
  computed: {
    textImage() {
      if (typeof this.pet == "object") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    goTo(page, pet) {
      localStorage.setItem("pet", JSON.stringify(pet));

      const view = this.publicProfileRoutePath();

      this.$router.push({
        name: page,
        params: { view: view, data: pet },
      });
    },

    publicProfileRoutePath() {
      const role = localStorage.getItem("role");

      if (role == 1 || role == 2 || role == 3) {
        return "contactview";
      } else if (role == 4 || role == 6) {
        return "serviceproviderview";
      } else {
        return "noncontactview";
      }
    },
  },
};
</script>

<style>
</style>