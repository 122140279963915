<template>
  <div class="mt-2 mb-6 md:my-0 flex flex-col lg:flex-row items-start">
    <SectionLeft class="hidden lg:block" />
    <div class="h-full md:h-screen w-full lg:w-6/12 px-4 lg:pr-32">
      <ContactNewSection />
      <img
        class="h-28 mt-8 md:mt-0 mb-4 md:mb-8 lg:hidden m-auto"
        src="@/assets/images/dataservice-logo.svg"
        alt="dataservice-logo"
      />
    </div>
    <NavMenuSecondary page="contacts" />
    <!-- <DataserviceLogo /> -->
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import ContactNewSection from "../components/contacts/ContactNewSection.vue";
import DataserviceLogo from "../components/DataserviceLogo.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue";

export default {
  components: {
    SectionLeft,
    ContactNewSection,
    DataserviceLogo,
    NavMenuSecondary,
  },
  beforeDestroy() {
    // clear the store
    this.$store.commit("errorForm", "");
    // this.$store.commit("error", "");
  },
};
</script>
