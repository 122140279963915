import { render, staticRenderFns } from "./ContactFriends.vue?vue&type=template&id=52d86026&scoped=true&"
import script from "./ContactFriends.vue?vue&type=script&lang=js&"
export * from "./ContactFriends.vue?vue&type=script&lang=js&"
import style0 from "./ContactFriends.vue?vue&type=style&index=0&id=52d86026&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52d86026",
  null
  
)

export default component.exports