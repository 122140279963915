<template>
  <li
    class="
      py-3
      px-4
      font-proximaMedium
      text-left
      cursor-pointer
      border-transparent border-r-4
      hover:bg-primary hover:text-white hover:border-r-4 hover:border-orange
      flex
      items-center
    "
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <!-- <component :is="component" width="24" height="24" :iconColor="hoverComp" /> -->
    <span class="ml-0 text-base">{{ title }}</span>
  </li>
</template>

<script>


export default {
  props: ["title"],
  components: {
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    hoverComp() {
      if (this.hover) {
        return "#fff";
      } else {
        return "#354E57";
      }
    },
  },
};
</script>