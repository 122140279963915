<template>
  <div>
    <ul class="flex text-primary">
      <li class="w-4/12 text-center">Name</li>
      <li class="w-5/12 text-center">Pets</li>
      <li class="w-3/12 text-center">Tags</li>
    </ul>
    <div v-for="(data, i) in interestGroup" :key="i">
      <ul class="px-2 md:px-0 w-full my-3 py-4 border-t flex items-center font-proximaLight">
        <li class="w-4/12 md:w-5/12 flex flex-col md:flex-row items-center">
          <div class="md:mr-2">
            <img class="h-6" src="@/assets/images/pp-lg.png" alt="pp-lg" />
          </div>
          <div class="text-center">{{ data.name }}</div>
        </li>
        <li class="w-4/12">
          <ContactPetPictures :pictures="data.picture" />
        </li>
        <li class="w-4/12 md:w-3/12 text-center">{{ data.tags }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import RadioOnoffBorder from "../forms/RadioOnoffBorder.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import ContactPetPictures from "./ContactPetPictures.vue";

export default {
  props: ["interestGroup"],
  components: {
    RadioOnoffBorder,
    BorderedButton,
    ContactPetPictures
  },
};
</script>

<style>
</style>